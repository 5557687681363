<template>
  <div class="grid grid-cols-1 bg-white md:p-5 md:my-5">
    <vue-breadcrumb :breadcrumbs="$route.name" />
    <div class="flex flex-row flex-wrap w-2/3 gap-8 mx-auto mt-24 md:gap-32">
      <div
        class="w-full p-4 border-2 border-gray-200 border-solid rounded md:w-1/2"
      >
        <h1 class="text-4xl font-bold text-left capitalize font-qwigley">
          estemos en contacto
        </h1>
        <p class="mt-4 text-left font-oxygen"></p>
      </div>
      <div class="w-full text-left md:w-1/3">
        <FormulateForm v-model="values" @submit="sendEmail">
          <FormulateInput
            type="text"
            name="nombre"
            label="cuál es tu nombre(*)"
            placeholder="ej: Carlos Gutierrez"
            validation="required"
            :validation-messages="{
              required: 'El Nombre es requerido'
            }"
          />

          <FormulateInput
            type="email"
            name="email"
            label="cuál es tu email(*)"
            placeholder="ej: carlos@ejemplo.com"
            validation="required"
            :validation-messages="{
              required: 'El E-Mail es requerido'
            }"
          />

          <FormulateInput
            type="tel"
            name="telefono"
            label="cuál es tu teléfono"
            placeholder="ej: +54 11 5637-5959"
          />

          <FormulateInput
            type="textarea"
            v-model="value"
            name="mensaje"
            label="dejame tu mensaje(*)"
            validation="required|max:50,length"
            validation-name="tweet"
            :help="`Solo quedan ${140 - value.length} caractéres. `"
            :validation-messages="{
              required: 'El Mensaje es requerido'
            }"
          />

          <FormulateInput
            type="submit"
            name="enviar"
            input-class="w-full py-2 font-bold text-white capitalize bg-green-400 rounded"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import emailjs from "emailjs-com";

export default {
  name: "Contacto",
  components: {
    "vue-breadcrumb": Breadcrumb
  },
  data() {
    return {
      values: null,
      value: ""
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .send(
          "service_6pl9c8j",
          "template_nwjq4x8",
          this.values,
          "user_ZNaLzu62VBo4JfiXHNavL"
        )
        .then(result => {
          this.$toastr.success(
            "Su mensaje fue enviado estaremos en contacto",
            "Mensaje enviado"
          );
          console.log("Exito", result.status);
          this.values = null;
        })
        .catch(error => {
          this.$toastr.error(error, "Mensaje enviado");
          console.error(error);
        });
    }
  }
};
</script>
